import type {
  NavigationGuardNext,
  NavigationGuardWithThis,
  NavigationHookAfter,
  RouteLocation,
} from 'vue-router';

import { useAuthStore } from '@/core/stores/auth';
import { analyticsPage } from '@/core/utils/usageAnalytics';
import {
  features,
  MATOMO_SPARK_EMPLOYEE_DIMENSION_ID,
} from '@/shared/misc/constants';

const _paq = window._paq;

const authPermissionCheck = (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  const isLoggedIn = useAuthStore().loggedIn;

  if (to.meta?.requiresAuth === true) {
    // display only to logged in user
    if (isLoggedIn) {
      next();
    } else {
      const name =
        typeof to.name === 'symbol'
          ? to.name.description
          : to.fullPath?.toString();
      next({ path: `/login`, query: { to: name } });
    }
  } else if (to.meta?.requiresAuth === false) {
    // display only to logged out user
    if (isLoggedIn) {
      next({ name: 'home' });
    } else {
      next();
    }
  } else {
    // if requiresAuth is not defined
    // display route irrespective of login status
    next();
  }
};

// BEFORE GUARDS

// App core is ready
const appCoreIsReady = async (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  await useAuthStore().loadingPromise;
  next();
};

// Auth check
const authCheck = (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  authPermissionCheck(to, from, next);
};

// onboarding check
const onboardingCheck = (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  const authStore = useAuthStore();
  const accountStatus = authStore.userAccount?.status;

  const emailVerified = !!authStore.userAccount?.emailVerifiedAt;
  const accountApproved = accountStatus === 'account-approved';

  if (
    !to.fullPath.includes('onboarding') &&
    !to.meta?.product &&
    to.meta?.requiresAuth === true &&
    (!emailVerified || !accountApproved)
  ) {
    next('/onboarding');
  } else {
    next();
  }
};

// product canAccess check
const productCanAccessCheck = (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  const authStore = useAuthStore();
  const availableProducts = authStore.availableSubscriptions?.map(
    (subscription) => subscription.product,
  ) as string[];

  if (!to.meta?.requiresAuth && !availableProducts?.length) {
    next();
    return;
  }

  if (!to.meta?.product || availableProducts.includes(to.meta?.product)) {
    next();
  } else if (
    availableProducts.length &&
    to.meta?.product &&
    !availableProducts.includes(to.meta?.product)
  ) {
    console.warn('You do not have access to this product. Redirecting..');
    if (to.meta.product === 'lng-freight-platform') {
      next('/freight-intro');
    } else if (to.meta.product === 'lng-basis-platform') {
      next('/cargo-intro');
    } else if (to.meta.product === 'access-platform') {
      next('/access-intro');
    } else if (to.meta.product === 'intraday-platform') {
      next('/intraday-intro');
    } else {
      next('/');
    }
  } else {
    authStore.resetSession();
    window.alert(
      'You have an account with us but it has no access to any products. If you would like to dispute this, you may contact us at info@sparkcommodities.com ',
    );
    next(false);
  }
};

// check access permission
const checkAccessPermission = (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  if (to.meta?.permissions?.length) {
    const authStore = useAuthStore();
    const canAccess =
      to.meta?.permissions.filter((permission) => {
        if (permission?.prefix) {
          return authStore.hasPermissionsAmong(
            permission.name,
            to.meta?.product,
          );
        } else {
          return authStore.hasPermission(permission.name, to.meta?.product);
        }
      }).length === to.meta?.permissions.length;
    if (!canAccess) {
      console.warn(
        'You do not have permission to access to this page. Redirecting..',
      );
      next('/403');
    }
  }
  next();
};

// redirect basic users to  basic-dashboard
const redirectBasicUsers = (
  to: RouteLocation,
  from: RouteLocation,
  next: NavigationGuardNext,
) => {
  const authStore = useAuthStore();
  const isBasicPlan = authStore.isBasicPlan;
  const inTrial = authStore.inTrial;
  const isPremiumPlan = authStore.isPremiumPlan;

  const emailVerified = !!authStore.userAccount?.emailVerifiedAt;
  const accountApproved = authStore.userAccount?.status === 'account-approved';

  // Set from router config, default to "not whitelisted"
  const isWhitelistedRoute = to.meta?.basic || false;
  if (to.meta?.product === 'lng-freight-platform') {
    if (
      emailVerified &&
      accountApproved &&
      isBasicPlan &&
      to.name !== features.FREIGHT_BASIC_DASHBOARD &&
      !isWhitelistedRoute &&
      !to.fullPath.includes('free-trial')
    ) {
      next({ name: features.FREIGHT_BASIC_DASHBOARD });
    } else if (
      emailVerified &&
      accountApproved &&
      (inTrial || isPremiumPlan) &&
      to.name === features.FREIGHT_BASIC_DASHBOARD &&
      !to.fullPath.includes('free-trial')
    ) {
      next({ name: features.FREIGHT_DASHBOARD });
    } else {
      next();
    }
  } else {
    next();
  }
};

export const beforeGuards: NavigationGuardWithThis<any>[] = [
  appCoreIsReady,
  authCheck,
  onboardingCheck,
  productCanAccessCheck,
  checkAccessPermission,
  redirectBasicUsers,
];

// AFTER ROUTE GUARDS

// analytics
const analytics = (to: RouteLocation) => {
  analyticsPage(to.path, { query: to.query });
  if (_paq) {
    _paq.push([
      'setCustomDimension',
      MATOMO_SPARK_EMPLOYEE_DIMENSION_ID,
      useAuthStore().isSparkUser ? 'yes' : 'no',
    ]);

    _paq.push(['setCustomUrl', '/' + to.fullPath]);
    _paq.push(['setDocumentTitle', to.meta?.title]);
    _paq.push(['trackPageView']);
  }
};

export const afterGuards: NavigationHookAfter[] = [analytics];
